import { AuthService } from './../../../../core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, filter, switchMap, tap } from 'rxjs/operators';

import { CustomerService } from '../../services/api/customer.service';
import { IpServiceService } from '../../services/api/ip-service.service';
import { ModalJQueryService } from '../../services/jquery/modal-jquery.service';
import { LocalStorage } from '../../../../helpers/local-storage.decorator';

interface SingUpBody {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  CountryID?: number;
  SourceID?: string;
}

@Component({
  selector: 'app-step-start',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @LocalStorage() user;
  public form: FormGroup;
  public signUpBody: SingUpBody;
  public showSpinnerModal = false;
  public showEmailExistsModal = false;
  private sid: string;
  private countryID: 1 | 2;

  isFormSubmited = false;

  constructor(
    private customerService: CustomerService,
    private fb: FormBuilder,
    private router: Router,
    private modalJQueryService: ModalJQueryService,
    private route: ActivatedRoute,
    private ipService: IpServiceService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.modalJQueryService.initModalJQuery();
    this.initForm();
    this.getSID();
  }

  private initForm(): void {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.form.valueChanges
      .pipe(
        map((form) => ({
          FirstName: form.firstName,
          LastName: form.lastName,
          Email: form.email,
          Password: form.password,
        }))
      )
      .subscribe((body) => {
        if (this.sid) {
          this.signUpBody = {
            ...body,
            SourceID: this.sid,
          };
        } else {
          this.signUpBody = body;
        }
      });
  }

  private getSID(): void {
    this.route.queryParams
      .pipe(
        filter((params) => params.sid),
        map((params) => params.sid)
      )
      .subscribe((sid) => {
        this.sid = sid;
      });
  }

  public signUp(): void {
    this.isFormSubmited = true;
    this.ipService.fetchCountryId$
      .pipe(
        tap((countryID) => (this.countryID = countryID)),
        switchMap((countryID) => {
          console.log('is getting called');
          return this.customerService.signUp({ ...this.signUpBody, countryID });
        })
      )
      .subscribe((user) => {
        if (!user) {
          console.error('User Info was not provided');
          return;
        }

        if (!user.IsNewCustomer) {
          this.showEmailExistsModal = true;
          setTimeout(() => {
            this.showEmailExistsModal = false;
            this.isFormSubmited = false;
          }, 3000);
        } else {
          /**
           * If it is a new customer the background login will take place and
           * sso login event will fire which is listening in header component
           * where user and token will be retrive and redirect to the actual
           * navigation.
           */
        }
      });
  }
}
